.ng-select.disable-clear-all .ng-clear-wrapper {
    display: none;
  }
  .ngx-pagination .current {
    background: #00b1c1 !important;

}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-label {
  color:black;
  font-family: "din-Light"  !important;
}
.mat-option-item
{
  color: black;

  font-family: "din-Light"  !important;
}
.mat-select-value-text {

  font-family: 'din-Light'  !important;
}
.mat-paginator-page-size-label {
  font-family: 'din-Light';
}
.mat-paginator-container{
  justify-content: center !important;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-commenting-o:before {
  content: "\f27a";
}
img.comment{
  max-width: 12px;
    display: inline-block;

}
.loginForm input::placeholder{
  color: #fff;
}
.sp-ff input::placeholder{
  color: #fff;
  font-size: 16px;
}
#v-pills-tabContent{
  min-height: 100vh;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/font/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 786px){
  body, app-home,.min_sp_mob_height{
    min-height: 100vh;
  }
  .sp_mob_view app-change-language-mobile .lang{
    background: #fff !important;
    color: #314a56 !important;
  }
  .slideMenu_mob{
    overflow: auto;
  }
  .slideMenu_mob .btn_menu{
    position: unset;
  }
}
@media (min-width: 786px){
  app-add-ticket .mat-dialog-content{
    overflow: visible !important;
  }
  app-add-comment .mat-dialog-content{
    overflow: visible !important;
  }
  app-cancel-ticket .mat-dialog-content{
    overflow: visible !important;
  }
}

app-search .mat-select-trigger {
  background: white;
}

.download_btn{
  position: relative;
  color:#fff;
  min-width: 209px;
min-height: 41px;
border-radius: 8px;
background-color: #314A56;
border: 0;
font-size: 13px;
cursor: pointer;
}


/* Start Mobile Media */

@media (max-width:480px) {
  .download_btn{
    display: block;
    margin: auto;
  }
}
/* End Mobile Media */


/* Start Logo  */

.services__wrapper__logo img{
  width: 70%;
}
/* Start Logo  */


