@font-face {
    font-family: 'Groningen';
    src: url('Groningen-Light.eot');
    src: local('Groningen Light'), local('Groningen-Light'),
        url('Groningen-Light.eot?#iefix') format('embedded-opentype'),
        url('Groningen-Light.woff2') format('woff2'),
        url('Groningen-Light.woff') format('woff'),
        url('Groningen-Light.ttf') format('truetype'),
        url('Groningen-Light.svg#Groningen-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Groningen';
    src: url('Groningen-Thin.eot');
    src: local('Groningen Thin'), local('Groningen-Thin'),
        url('Groningen-Thin.eot?#iefix') format('embedded-opentype'),
        url('Groningen-Thin.woff2') format('woff2'),
        url('Groningen-Thin.woff') format('woff'),
        url('Groningen-Thin.ttf') format('truetype'),
        url('Groningen-Thin.svg#Groningen-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Groningen';
    src: url('Groningen-Bold.eot');
    src: local('Groningen Bold'), local('Groningen-Bold'),
        url('Groningen-Bold.eot?#iefix') format('embedded-opentype'),
        url('Groningen-Bold.woff2') format('woff2'),
        url('Groningen-Bold.woff') format('woff'),
        url('Groningen-Bold.ttf') format('truetype'),
        url('Groningen-Bold.svg#Groningen-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Groningen';
    src: url('Groningen-Regular.eot');
    src: local('Groningen Regular'), local('Groningen-Regular'),
        url('Groningen-Regular.eot?#iefix') format('embedded-opentype'),
        url('Groningen-Regular.woff2') format('woff2'),
        url('Groningen-Regular.woff') format('woff'),
        url('Groningen-Regular.ttf') format('truetype'),
        url('Groningen-Regular.svg#Groningen-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

